import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Doodle #3",
  "featuredImage": "images/Flower+Drops.jpg",
  "coverAlt": "Doodle 3",
  "description": "Doodle 3 - Flower drops - relief and rescue mission inspired 🙏🏾",
  "date": "2018-03-15",
  "dateModified": "2018-03-15",
  "category": "portfolio",
  "pinned": false,
  "featured": false,
  "tags": ["Doodles", "Graphic Design"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Doodle!`}</p>
    <p>{`The idea of parachutes with supplies falling down to help those who need relief resonates with me for some reason. Airdrop. Or maybe better, Lovedrops.`}</p>
    <p><img parentName="p" {...{
        "src": "images/Flower%2BDrops.jpg",
        "alt": "Flower drops"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      